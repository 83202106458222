import { tagOf } from "../../../classes/misc-util";
export function openSidebarOnMenuItem(menuItem, element, data) {
    menuItem.internalSidebarMutator.emit({
        open: true,
        menuItemElement: element,
        data,
    });
}
export function closeSidebarOnMenuItem(menuItem, element, data) {
    menuItem.internalSidebarMutator.emit({
        open: false,
        menuItemElement: element,
        data,
    });
}
export function isMenuItem(menuEntry) {
    return (ITEM_FUNCTION_RENDER_SIDEBAR_CONTENT in menuEntry || tagOf(menuEntry) === "dx-header-menu-item");
}
export function isMenuCustom(menuEntry) {
    return tagOf(menuEntry) === "dx-header-menu-custom";
}
export function isMenuGroup(menuEntry) {
    return (GROUP_FUNCTION_GET_INNER_MENU_ITEM in menuEntry || tagOf(menuEntry) === "dx-header-menu-group");
}
export function isMenuLink(menuEntry) {
    return (LINK_PROPERTY_CONNECTED_NAVIGATION_VALUE in menuEntry ||
        tagOf(menuEntry) === "dx-header-menu-link");
}
// Helps to make conditions within isMenuItem and isMenuGroup typesafe
const GROUP_FUNCTION_GET_INNER_MENU_ITEM = "getInnerMenuItems";
const ITEM_FUNCTION_RENDER_SIDEBAR_CONTENT = "renderSidebarContent";
const LINK_PROPERTY_CONNECTED_NAVIGATION_VALUE = "connectednavigationvalue";
